import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Typography } from '@mui/material';
import { useMakeStyles } from '../hooks/makeStyles';
import firebaseService from '../firebase-service';
import { httpsCallable } from 'firebase/functions';
import { PageContent } from '../styled/PageContent';
import { PageSection } from '../styled/PageSection';
import { PageSectionTitle } from '../styled/PageSectionTitle';
import { Paragraph } from '../styled/Paragraph';

const sendRequest = httpsCallable<{email: string, recaptcha: string}, void>(
    firebaseService.functions,
    'accountDelete'
);

const AccountDeletionRequest = () => {
  const [email, setEmail] = useState('');
  const [error, setError ] = useState<string | null>(null); 
  const [success, setSuccess] = useState<boolean>(false); 
  const [captchaValue, setCaptchaValue] = useState<string |null>(null);
  const [loading, setLoading] = useState<boolean>(false);


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError(null);

    if (!captchaValue) {
      alert('Please complete the reCAPTCHA.');
      return;
    }

    try {
      setLoading(true);
        await sendRequest({email, recaptcha: captchaValue});

      setSuccess(true);
      // reset form
        setEmail('');
    } catch (error) {
        setError((error as any).message || 'An error occurred');
        console.error('Error submitting request:', error);
    }
    finally
    {
        setLoading(false);
    }
  };

  return (
    <PageContent>
      <PageSection>
        <PageSectionTitle>Request your account to be deleted</PageSectionTitle>
        <form onSubmit={handleSubmit}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2,  alignItems:'center'}}>

                <TextField sx={{width: 300}} id="email-address" label="Email Address" variant="outlined" 
                    type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                    onChange={(value: any) => setCaptchaValue(value)}
                />

                <Paragraph>
                  By submitting this form, you are requesting the deletion of your account and associated data.
                  An email will be sent to the address you provide with details about the deletion process.
                  This email will contain a confirmation link that you must click to finalize the request.
                  Please ensure that you have access to the email address associated with this account,
                  as ownership verification will be required to complete the deletion process.
                </Paragraph>

                <Button variant="outlined" type="submit" disabled={loading}>
                  {loading ? 'Sending...' : 'Submit Request'}
                  </Button>

                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">Your delete account request has been submitted. The processing of your request may take up to a few days.
                    You will receive an email with further instructions. </Alert>}
            </Box>
        </form>
    </PageSection>
    </PageContent>
  );
};

export default AccountDeletionRequest;
