import { styled } from "@mui/material/styles";

export const Paragraph = styled('p')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: '1em',
    lineHeight: '2em',
    textAlign: 'justify',
    textJustify: 'inter-word',
  }));

  export const StandOutParagraph = styled(Paragraph)(({ theme }) => ({ 
    color: theme.palette.standout.main ,
    fontFamily: `"DM Sans", sans-serif`,
    fontStyle: 'italic',
    fontWeight: 500,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderTopWidth: '2px',
    borderBottomWidth: '2px',
    borderTopColor: theme.palette.standout.accent,
    borderBottomColor: theme.palette.standout.accent,
    padding: '0.5em 1em',
  }))