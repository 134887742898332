import {
  AppBar,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { Link, Route, Routes } from 'react-router-dom'
import Logo from './components/Logo'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import HelpIcon from '@mui/icons-material/Help'
import PolicyIcon from '@mui/icons-material/Policy'
import AccountDelete from './pages/AccountDelete'
import Home from './pages/Home'
import HowItWorks from './pages/HowItWorks'
import PrivacyPolicy from './pages/PrivacyPolicy'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import ThemePreview from './pages/ThemePreview'
import PaletteIcon from '@mui/icons-material/Palette'

const navigationRoutes = [
  { path: '/', element: <Home />, text: 'Home', icon: <HomeIcon /> },
  {
    path: '/howitworks',
    element: <HowItWorks />,
    text: 'How It Works',
    icon: <HelpIcon />,
  }, // Assuming an icon for demonstration
  {
    path: '/privacy',
    element: <PrivacyPolicy />,
    text: 'Privacy Policy',
    icon: <PolicyIcon />,
  },
  {
    path: '/accountDelete',
    element: <AccountDelete />,
    text: 'Account Delete',
    icon: <PolicyIcon />,
    skipOnMenu: true,
  }, // Assuming an icon for demonstration
  {
    path: '/themepreview',
    element: <ThemePreview />,
    text: 'Theme Preview',
    icon: <PaletteIcon />,
    skipOnMenu: true,
  },
]

const App = () => {
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null)
  const isDev = !!process.env.REACT_APP_FIREBASE_PROJECT_ID?.endsWith('-dev')

  const NavLink = styled(Typography)(({ theme }) => ({
    textDecoration: 'underline',
    fontSize: '0.9em',
    letterSpacing: '0.1em',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }))

  const Footer = styled('footer')(({ theme }) => ({
    position: 'fixed',
    bottom: 0, // Aligns the footer at the bottom
    borderTop: `1px solid ${theme.palette.divider}`, // Adds a border at the top of the footer
    marginTop: 'auto', // Pushes the footer to the bottom of the flex container
    textAlign: 'center', // Centers the footer content
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  }))

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuElement(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setMenuElement(null)
  }

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{ backgroundColor: '#fff' }}
      >
        <Container maxWidth="lg">
          <Toolbar
            disableGutters
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Box
              sx={{
                flex: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'start',
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={menuElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(menuElement)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {navigationRoutes
                  .filter((route) => !route.skipOnMenu)
                  .map((route) => (
                    <MenuItem
                      key={route.path}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={route.path}
                    >
                      <ListItemIcon>{route.icon}</ListItemIcon>
                      <Typography textAlign="center">{route.text}</Typography>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Logo isDev={isDev} />
            </Link>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
              {navigationRoutes.map(
                (route) =>
                  !route.skipOnMenu && (
                    <Link
                      style={{ textDecoration: 'none', zIndex: 1 }}
                      key={route.path}
                      to={route.path}
                    >
                      <NavLink>{route.text}</NavLink>
                    </Link>
                  )
              )}
            </Box>
            <Box sx={{ flex: 1, display: { xs: 'flex', md: 'none' } }} />
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="lg">
        <Toolbar />
        <Box component="main" sx={{ flexGrow: 1, pb: 10, pt: 3 }}>
          <Routes>
            {navigationRoutes.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
            <Route path="*" element={<Home />} />
          </Routes>
        </Box>
      </Container>
      <Footer>
        {' '}
        <Typography variant="body2">
          © {new Date().getFullYear()}{' '}
          <a href="https://apperytime.com">Apperytime</a>. All rights reserved.
        </Typography>
      </Footer>
    </>
  )
}

export default App
